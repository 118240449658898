<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    min-width="290px"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        background-color="#464646"
        class="rounded-lg"
        flat
        hide-details="auto"
        readonly
        solo
        v-on="on"
      >
        <template v-slot:prepend-inner>
          <v-icon class="mr-1">
            mdi-calendar
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      no-title
      range
      scrollable
    >
      <v-spacer />

      <v-btn
        color="primary"
        text
        @click="menu = false"
      >
        Отмена
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveDates"
      >
        ОК
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      dates: [],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    saveDates() {
      this.$refs.menu.save(this.dates);
      this.$emit('save', this.dates);
    },
  },
};
</script>
